import React, {useEffect, useState} from "react";
import { Container, Row, Col } from 'reactstrap';
import logo from '../../assets/images/radiant_logo.svg';
import menu_icon from '../../assets/images/menu_icon.svg';
import facebook_icon from '../../assets/images/facebook_icon.svg';
import linkedin_icon from '../../assets/images/linkedin_icon.svg';
import youtub_icon from '../../assets/images/youtub_icon.svg';
import './Style/header_style.css';

export default () => {

    const [menuHeight, setMenuHeight] = useState('0%');
    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [polylineWidth, setPolylineWidth] = useState(0);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        setPolylineWidth(width/5);

        return () => window.removeEventListener("resize", updateDimensions);
    }, [updateDimensions]);

    const openNav = () => {
        setMenuHeight('100%')
    };
    const closeNav = () => {
        setMenuHeight('0%')
    };

    return (
        <>
            <div className="nav_menu_style" style={{height: menuHeight}}>
                <div className="d-flex flex-row w-100 justify-content-between border_bottom" style={{height: 135}}>
                    <div className="w-100 text-center border_right align_center">
                        <img className="white" src={logo} alt="Radiant" style={{margin: 'auto'}} />
                    </div>
                    <div className="w-100 border_right">
                        <svg width="100%" height="100%">
                            <polyline points={`0,0 ${polylineWidth},135`} strokeOpacity="0.08" stroke="#ffffff" strokeWidth="1" fill="none"></polyline>
                        </svg>
                    </div>
                    <div className="w-100 border_right">
                        <svg width="100%" height="100%">
                            <polyline points={`${polylineWidth},0 0,135`} strokeOpacity="0.08" stroke="#ffffff" strokeWidth="1" fill="none"></polyline>
                        </svg>
                    </div>
                    <div className="w-100 border_right">
                        <svg width="100%" height="100%">
                            <polyline points={`0,0 ${polylineWidth},135`} strokeOpacity="0.08" stroke="#ffffff" strokeWidth="1" fill="none"></polyline>
                        </svg>
                    </div>
                    <div className="w-100">
                        <div className="w-100 h-100 align_center">
                            <div className='close_btn' onClick={() => closeNav()} style={{textDecoration: 'none'}}>
                                &times; Close
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-row w-100 h-100 justify-content-between">
                    <div className="w-25 border_right"></div>
                    <div className="w-100 border_right">
                        <div className="main_menu border_bottom">
                            <ul className="align_center">
                                <li><a href="">HOME</a></li>
                                <li><a href="">PRODUCTS</a></li>
                                <li><a href="">SUSTAINABLITY</a></li>
                                <li><a href="">GLOBAL AMPLIFICATIONS</a></li>
                                <li><a href="">Pharmacovigilance</a></li>
                                <li><a href="">CSR</a></li>
                                <li><a href="">CAREER</a></li>
                            </ul>
                        </div>
                        <div className="main_menu border_bottom">
                            <span>OUR COMPANIES</span>
                            <div className="company_list">
                                <label><a target="_blank" href="http://www.radiantpharmabd.com/">Radiant Pharmaceuticals Ltd</a></label>
                                <label><a target="_blank" href="http://radiantnutrabd.com/">Radiant Nutraceuticals Ltd</a></label>
                                <label><a target="_blank" href="http://pharmacil.com/">Pharmacil Limited</a></label>
                                <label><a target="_blank" href="https://www.julpharbd.com/">Julphar Bangladesh Ltd</a></label>
                                <label><a target="_blank" href="">Radiant Export Import Enterprise</a></label>
                                <label><a target="_blank" href=" https://rbclbd.com/">Radiant Business Consortium Limited</a></label>
                                <label><a target="_blank" href="http://radiantdistbd.com/">Radiant Distribution Ltd</a></label>
                                <label><a target="_blank" href="http://oncosmolbiol.com/">Radiant Oncos Molbiol Ltd</a></label>
                                <label><a target="_blank" href="">Shamutshuk Printers Limited</a></label>
                                <label><a target="_blank" href="https://aeromate.com.bd/">Aeromate Services Limited</a></label>
                                <label><a target="_blank" href="https://aerowing.com.bd/">AeroWing Aviation Limited</a></label>
                            </div>
                        </div>
                        <div className="main_menu border_bottom">
                            <span>About</span>
                            <div className="company_list">
                                <label><a href="http://www.radiantpharmabd.com/about-us">ABOUT RADIANT</a></label>
                                <label><a href="http://www.radiantpharmabd.com/mission">MISSION VISION & CORE VALUE</a></label>
                                <label><a href="http://www.radiantpharmabd.com/history">History</a></label>
                                <label><a href="http://www.radiantpharmabd.com/meet-our-chairman">CHAIRMAN MESSAGES</a></label>
                                <label><a href="http://www.radiantpharmabd.com/contact-us">CONTACT US</a></label>
                            </div>
                        </div>
                        <div className="d-flex flex-row w-100 justify-content-between border_bottom" style={{height: 135}}>
                            <div className="w-50 border_right">
                                <svg width="100%" height="100%">
                                    <polyline points={`0,0 ${polylineWidth+30},135`} strokeOpacity="0.08" stroke="#ffffff" strokeWidth="1" fill="none"></polyline>
                                </svg>
                            </div>
                            <div className="w-100 align_center">
                                <div className="w-100 text-center" style={{color: '#ffffff', fontSize: 11}}>
                                    Copyright © Radiant Pharmaceuticals Limited 2020. <br />
                                    All Right Reserved By Radiant Pharmaceuticals Limited
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-25 d-flex flex-column">

                        <div className="border_bottom h-100 align_center">
                            <a className="w-100 text-center" target="_blank" href="https://www.linkedin.com/company/13714589">
                                <div className="w-100 text-center">
                                    <img src={linkedin_icon} />
                                    <div style={{color: '#ffffff', fontSize: 12, marginTop: 10}}>Linkedin</div>
                                </div>
                            </a>
                        </div>

                        <div className="border_bottom h-100 align_center">
                            <a className="w-100 text-center" target="_blank" target="_blank" href="https://www.facebook.com/RadiantPharmaceuticalsLimited">
                                <div className="w-100 text-center">
                                    <img src={facebook_icon} />
                                    <div style={{color: '#ffffff', fontSize: 12, marginTop: 10}}>Facebook</div>
                                </div>
                            </a>
                        </div>

                        <div className="border_bottom h-100 align_center">
                            <a className="w-100 text-center" target="_blank" href="https://www.youtube.com/channel/UCeuvxGn6xJB-4KbRGJYwf0A">
                                <div className="w-100 text-center">
                                    <img width="24px" src={youtub_icon} />
                                    <div style={{color: '#ffffff', fontSize: 12, marginTop: 10}}>Youtub</div>
                                </div>
                            </a>
                        </div>
                        <div className="border_bottom text-center h-100">

                        </div>
                    </div>
                </div>

            </div>

            <div className="page-header-container">
                <div className={"d-flex justify-content-between"}>
                    <div className="radiant_logo">
                        <img src={logo} alt="Radiant"/>
                    </div>
                    <div className="custom_menu" onClick={() => openNav()}>
                        <div className="w-100 text-center">
                            <img src={menu_icon} alt="Menu"/>
                            <span>Menu</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
