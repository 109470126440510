import React, { useEffect, useRef, useState } from "react";
import ImageSlider from "../common-component/ImageSlider";
import TextSlider from "../common-component/TextSlider";
import "./home_style.css";
import playButton from "../../assets/images/play_button.svg";
import iphone_image from "../../assets/images/iphone_image.svg";
import ImageSliderControl from "../common-component/ImageSliderControl";
import radiant_rcl from "../../assets/images/company_list/radiant_rcl.svg";
import radiant_aal from "../../assets/images/company_list/radiant_aal.svg";
import radiant_asl from "../../assets/images/company_list/radiant_asl.svg";
import radiant_bcl from "../../assets/images/company_list/radinat_bcl.png";
import radiant_pcl from "../../assets/images/company_list/radiant_pcl.png";
import radiant_dl from "../../assets/images/company_list/radiant_dl.svg";
import radiant_eie from "../../assets/images/company_list/radiant_eie.svg";
import radiant_jul from "../../assets/images/company_list/radiant_jul.svg";
import radiant_nu from "../../assets/images/company_list/radiant_nu.svg";
import radiant_oml from "../../assets/images/company_list/radiant_oml.svg";
import radiant_ph from "../../assets/images/company_list/radiant_ph.svg";
import radiant_spl from "../../assets/images/company_list/radiant_spl.svg";
import bloomberg_img from "../../assets/images/clients/bloomberg.svg";
import hubspot_img from "../../assets/images/clients/hubspot.svg";
import usa_today_img from "../../assets/images/clients/usa_today.svg";
import building_icon_1 from "../../assets/images/building_icon_1.svg";
import building_icon_2 from "../../assets/images/building_icon_2.svg";
import card_hole_img from "../../assets/images/card_hole.png";
import hiring from "../../assets/images/hiring.svg";
import { Input } from "reactstrap";
import bog_post_img from "../../assets/images/bog_post.png";
import bog_post_small from "../../assets/images/bog_post_small.png";
import contact_us_img from "../../assets/images/contact_us_img.svg";
import map_icon from "../../assets/images/map_icon.svg";

export const Context = React.createContext({
  textChangeValue: ["active", "", "", ""],
  setTextChangeValue: () => {},
  imageChangeValue: null,
  setImageChangeValue: () => {},
  imageChangeComplete: 0,
  setImageChangeComplete: () => {},
});

const Home = () => {
  const [textChangeValue, setTextChangeValue] = useState(["active", "", "", ""]);
  const [imageChangeValue, setImageChangeValue] = useState(null);
  const [imageChangeComplete, setImageChangeComplete] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [polylineWidth, setPolylineWidth] = useState(0);

  const [radinatCompanyPolylineWidth, setRadinatCompanyPolylineWidth] = useState(0);
  const [blogControlBoxPolylineWidth, setBlogControlBoxPolylineWidth] = useState(0);
  const [contactBoxPolylineWidth, setContactBoxPolylineWidth] = useState(0);
  const [contactViewBoxPolylineWidth, setContactViewBoxPolylineWidth] = useState(0);
  const [contactViewBoxPolylineHeight, setContactViewBoxPolylineHeight] = useState(0);
  const [landContainerHeight, setLandContainerHeight] = useState(0);

  const radinatCompanyPolylineRef = useRef();
  const blogControlBoxPolylineRef = useRef();
  const contactBoxPolylineRef = useRef();
  const contactViewBoxPolylineRef = useRef();
  const landContainerRef = useRef();

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    setRadinatCompanyPolylineWidth(radinatCompanyPolylineRef.current.clientWidth);
    setBlogControlBoxPolylineWidth(blogControlBoxPolylineRef.current.clientWidth);
    setContactBoxPolylineWidth(contactBoxPolylineRef.current.clientWidth);
    setContactViewBoxPolylineWidth(contactViewBoxPolylineRef.current.clientWidth);
    setContactViewBoxPolylineHeight(contactViewBoxPolylineRef.current.clientHeight);
    setLandContainerHeight(landContainerRef.current.clientHeight);

    console.log(landContainerRef.current.clientHeight);
    setPolylineWidth(width / 5);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [updateDimensions]);

  return (
    <Context.Provider value={{ textChangeValue, setTextChangeValue, imageChangeValue, setImageChangeValue, imageChangeComplete, setImageChangeComplete }}>
      <section className={"index-first-screen"} style={{ height: height }}>
        <ImageSlider />

        <div className={"container"}>
          <ul className="lines dark">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div style={{ width: "75%", position: "relative" }}>
              <TextSlider />
            </div>
            <div className="d-flex flex-column justify-content-center" style={{ width: "25%", position: "relative" }}>
              <div className="w-100">
                <div className="bgv">
                  <video style={{ width: "100%" }} className="bg-video" loop={true} muted="" autoPlay={true} playsinline="">
                    <source src="/video/about/index-about-video.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="w-100" style={{ marginTop: -6 }}>
                <div className="video-play-with-description w100">
                  <div className="play-button">
                    <img src={playButton} />
                  </div>
                  <div className="video-description">
                    <div style={{ fontSize: "16px" }}>Watch video</div>
                    <div style={{ fontSize: "12px" }}>2:15min</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row controls border_bottom_white">
          <ImageSliderControl />
        </div>
        <div className="arrow-scroll-down" style={{ width: 150 }}>
          <svg>
            <path fill="none" strokeWidth="2" d="M7 22l11-10L7 2"></path>
          </svg>
        </div>
      </section>

      <section className="index-second-screen">
        <div className={"container"}>
          <ul className="lines">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div style={{ width: "50%", position: "relative", zIndex: 15 }}>
              <div className="section-title">
                <div style={{ color: "#EF1C20", fontSize: 16 }}>Radiant Companies</div>
                <div style={{ color: "#333333", fontSize: 40, marginTop: 20 }}>A trusted name for quality pharmaceutical products</div>
                <div style={{ color: "#4F4F4F", fontSize: 12, marginTop: 30, textAlign: "justify" }}>Radiant Consortium Companies consists of over 11 companies in various industries prioritizing in manufacturing, importing, exporting and distribution of Pharmaceutical products. Understanding of local culture, business and teams consisting of empowered employees having diverse background has allowed Radiant to partner with leading developers of Pharmaceutical Biotechnology.</div>
              </div>
            </div>
            <div style={{ width: "50%", position: "relative" }}>
              <div style={{ zIndex: -1, right: 0, top: 215, height: 200, width: "50%", backgroundColor: "#332F2F", margin: 0, padding: 0, position: "absolute" }}>
                <div className="text-right" style={{ marginTop: "30%", marginRight: 40, fontWeight: "bold", fontSize: 56, color: "#ffffff" }}>
                  11
                </div>
                <div className="text-right" style={{ marginRight: 40, fontSize: 14, color: "#999194" }}>
                  11 companies
                </div>
              </div>
              <div className="text-right">
                <img style={{ width: "100%" }} src={iphone_image} />
              </div>
            </div>
          </div>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div className="row" style={{ margin: 0, padding: 0, width: "100%", zIndex: 16 }}>
              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="http://www.radiantpharmabd.com/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_top_white border_bottom_white border_left_white border_right_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Radiant Pharmaceuticals Limited</div>
                    <div className="text-center">
                      <img src={radiant_ph} />
                    </div>
                  </div>
                </a>
              </div>

              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="http://radiantnutrabd.com/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_top_white border_bottom_white border_right_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Radiant Nutraceuticals Ltd</div>
                    <div className="text-center">
                      <img src={radiant_nu} />
                    </div>
                  </div>
                </a>
              </div>

              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="http://jenphar.com/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_top_white border_bottom_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Jenphar Bangladesh Limited</div>
                    <div className="text-center">
                      <img src={radiant_jul} />
                    </div>
                  </div>
                </a>
              </div>

              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="#" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_left_white border_bottom_white border_right_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Radiant Export Import Enterprise</div>
                    <div className="text-center">
                      <img src={radiant_eie} />
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className="row" style={{ margin: 0, padding: 0, width: "100%", zIndex: 16 }}>
              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="https://rbclbd.com/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box _white border_bottom_white border_right_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Radiant Business Consortium Limited</div>
                    <div className="text-center">
                      <img width="140px" src={radiant_bcl} />
                    </div>
                  </div>
                </a>
              </div>

              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="http://radiantdistbd.com/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_bottom_white border_right_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Radiant Distribution Limited</div>
                    <div className="text-center">
                      <img src={radiant_dl} />
                    </div>
                  </div>
                </a>
              </div>

              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="http://oncosmolbiol.com/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_bottom_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Radiant Oncos Molbiol Limited</div>
                    <div className="text-center">
                      <img src={radiant_oml} />
                    </div>
                  </div>
                </a>
              </div>

              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="#" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_left_white border_bottom_white border_right_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Shamutshuk Printers Limited</div>
                    <div className="text-center">
                      <img src={radiant_spl} />
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className="row" style={{ margin: 0, padding: 0, width: "100%", zIndex: 16 }}>
              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="https://aeromate.com.bd/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_bottom_white border_right_white d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Aeromate Services Limited</div>
                    <div className="text-center">
                      <img src={radiant_asl} />
                    </div>
                  </div>
                </a>
              </div>

              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="https://aerowing.com.bd/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_bottom_white  d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">AeroWing Aviation Limited</div>
                    <div className="text-center">
                      <img src={radiant_aal} />
                    </div>
                  </div>
                </a>
              </div>

              <div style={{ width: "25%", height: 250 }}>
                <a target="_blank" href="https://radiantcare.com.bd/" style={{ textDecoration: "none" }}>
                  <div className="radinat_company_list_box border_bottom_white  d-flex flex-column">
                    <div className="radinat_company_list_name mb-auto p-2">Radiant Care Limited</div>
                    <div className="text-center">
                      <img style={{ width: 200 }} src={radiant_rcl} />
                    </div>
                  </div>
                </a>
              </div>

              <div ref={radinatCompanyPolylineRef} className="border_bottom_white" style={{ width: "25%", height: 250 }}>
                <svg width="100%" height="100%">
                  <polyline points={`0,0, ${radinatCompanyPolylineWidth},250`} strokeOpacity="1" stroke="#F0EBED" strokeWidth="1" fill="none"></polyline>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ display: "none" }} className="index-three-screen">
        <div id="land-svg" style={{ height: landContainerHeight, margin: 0, padding: 0, zIndex: 0 }}>
          <svg width="100%" height="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
            <polyline fill="#FF4A4A" points={`${0},${landContainerHeight} ${0.309288537549407 * width},${0.336292155214764 * width} ${0.5 * width},${0.431647886440061 * width} ${0.881422924901186 * width},${0.207453116296885 * width} ${1 * width},${0.295573056076813 * width} ${1 * width},${0.518087396289413 * width} ${width},${landContainerHeight} ${0},${landContainerHeight} ${0},${landContainerHeight}`}></polyline>
            {/*<polyline fill="#FF4A4A" points={`${0},${0.51334431328546*width} ${0.309288537549407*width},${0.336292155214764*width} ${0.5*width},${0.431647886440061*width} ${0.881422924901186*width},${0.207453116296885*width} ${1*width},${0.295573056076813*width} ${1*width},${0.518087396289413*width} ${1*width},${0.514925340953444*width} ${0*width},${0.514925340953444*width} ${0*width},${0.514925340953444*width}`}></polyline>*/}
          </svg>
        </div>
        <div ref={landContainerRef} className={"container"}>
          <ul className="lines">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="land-buildings" style={{ position: "relative", margin: 0, padding: 0 }}>
            <div className="building" style={{ left: 60, top: 480 }}>
              <img src={building_icon_1} style={{ width: "80%" }} />
            </div>
            <div className="building" style={{ left: 300, top: 650 }}>
              <img src={building_icon_2} style={{ width: "80%" }} />
            </div>
            <div className="building" style={{ left: 560, top: 570 }}>
              <img src={building_icon_1} style={{ width: "80%" }} />
            </div>
            <div className="building" style={{ left: 800, top: 680 }}>
              <img src={building_icon_1} style={{ width: "80%" }} />
            </div>
            <div className="building" style={{ left: 800, top: 320 }}>
              <img src={building_icon_2} style={{ width: "80%" }} />
            </div>
            <div className="building" style={{ left: 450, top: 900 }}>
              <img src={building_icon_1} style={{ width: "80%" }} />
            </div>
            <div className="building" style={{ left: 650, top: 800 }}>
              <img src={building_icon_2} style={{ width: "80%" }} />
            </div>
            <div className="building" style={{ left: 800, top: 1000 }}>
              <img src={building_icon_1} style={{ width: "80%" }} />
            </div>
          </div>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div style={{ width: "50%", position: "relative" }}>
              <div className="section-title" style={{ paddingTop: 96, paddingBottom: 0.256981027667984 * width }}>
                <div style={{ color: "#EF1C20", fontSize: 16 }}>About Radiant Group</div>
                <div style={{ color: "#333333", fontSize: 40, marginTop: 20 }}>A leading nutraceutical company in Bangladesh</div>
                <div style={{ color: "#4F4F4F", fontSize: 12, marginTop: 30 }}>Our commitment towards sustained excellence includes our efforts to benefit the coOur commitment towards sustained</div>
              </div>
            </div>
          </div>
        </div>
        <div className="building-interactive" style={{ position: "relative", marginTop: 0, padding: 0 }}>
          <div className="container" style={{ position: "relative" }}>
            <ul className="lines">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="row" style={{ margin: 0, padding: 0, width: "25%" }}>
              <div className="col building-equipment-box">
                <div>
                  <div style={{ color: "#ffffff", fontWeight: "bold", fontSize: 50 }}>2005</div>
                  <div style={{ color: "#F7F7F7", fontSize: 14 }}>Radiant group Establisehd Year</div>
                </div>
                <div style={{ marginTop: 50 }}>
                  <div style={{ color: "#ffffff", fontWeight: "bold", fontSize: 50 }}>40500</div>
                  <div style={{ color: "#F7F7F7", fontSize: 14 }}>Radiant group Establisehd Year</div>
                </div>
                <div style={{ marginTop: 50 }}>
                  <div style={{ color: "#ffffff", fontWeight: "bold", fontSize: 50 }}>20k</div>
                  <div style={{ color: "#F7F7F7", fontSize: 14 }}>Radiant group Establisehd Year</div>
                </div>
              </div>
            </div>
            <div className="row" style={{ margin: 0, paddingBottom: 60, position: "relative" }}>
              <div className="col" style={{ margin: 0, padding: 0, position: "relative" }}>
                <div className="d-flex" style={{ marginTop: 80 }}>
                  <div>
                    <div style={{ color: "#ffffff", fontWeight: "bold", fontSize: 30 }}>20k</div>
                    <div style={{ color: "#F7F7F7", fontSize: 14 }}>Radiant group Establisehd Year</div>
                  </div>
                  <div style={{ marginLeft: 80 }}>
                    <div style={{ color: "#ffffff", fontWeight: "bold", fontSize: 30 }}>20k</div>
                    <div style={{ color: "#F7F7F7", fontSize: 14 }}>Radiant group Establisehd Year</div>
                  </div>
                  <div style={{ marginLeft: 80 }}>
                    <div style={{ color: "#ffffff", fontWeight: "bold", fontSize: 30 }}>20k</div>
                    <div style={{ color: "#F7F7F7", fontSize: 14 }}>Radiant group Establisehd Year</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ display: "none" }} className="index-four-screen">
        <div className={"container"}>
          <ul className="lines dark">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div style={{ width: "50%", position: "relative" }}>
              <div className="section-title">
                <div style={{ color: "#E0E0E0", fontSize: 16 }}>Pharmaceuticals Products</div>
                <div style={{ color: "#FFFFFF", fontSize: 33, marginTop: 20 }}>
                  Radiant Have more than <label style={{ color: "#EF1C20" }}>500+</label> <label style={{ color: "#EF1C20" }}>Pharmaceuticals Products</label> with different categories.
                </div>
                <div style={{ color: "#E0E0E0", fontSize: 12, marginTop: 30 }}>Our commitment towards sustained excellence includes our efforts to benefit the coOur commitment towards sustained</div>
              </div>
            </div>
            <div style={{ width: "50%", position: "relative" }}>
              <div className="text-right" style={{ paddingTop: "30%" }}>
                <img style={{ width: "70%" }} src={card_hole_img} />
              </div>
            </div>
          </div>
          <div className="row" style={{ margin: 0, paddingBottom: 60 }}>
            <div className="border_bottom_dark border_top_dark" style={{ width: "50%", position: "relative", paddingLeft: 1 }}>
              <Input type="text" placeholder="Search product name here..." style={{ position: "relative", borderRadius: 0, color: "#999194", backgroundColor: "#332F2F", border: "none", padding: 40, zIndex: 15 }} />
            </div>
            <div className="border_bottom_dark border_left_dark border_top_dark" style={{ width: "30%", position: "relative", zIndex: 20, backgroundColor: "#332F2F" }}>
              <div className="row" style={{ margin: 0, padding: 0 }}>
                <div style={{ width: "70%", position: "relative" }}>
                  <Input className="text-center" value="Search by brand" type="text" placeholder="Select type" style={{ position: "relative", borderRadius: 0, color: "#ffffff", backgroundColor: "#332F2F", border: "none", padding: 40, zIndex: 15 }} />
                </div>
                <div className="text-center border_left_dark" style={{ width: "30%", paddingTop: "10%", position: "relative" }}>
                  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.99961 9.03133C8.81459 9.03133 8.6371 8.95765 8.50622 8.82705L0.870989 1.19098C0.598342 0.918336 0.598342 0.476574 0.870989 0.204485C1.14364 -0.0681618 1.58512 -0.0681618 1.85749 0.204485L8.99961 7.34745L16.1423 0.205044C16.4149 -0.0676029 16.8564 -0.0676029 17.1288 0.205044C17.4012 0.477691 17.4014 0.919173 17.1288 1.19154L9.493 8.82677C9.36212 8.95765 9.18463 9.03133 8.99961 9.03133Z" fill="#F2F2F2" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="text-center border_bottom_dark border_left_dark border_top_dark" style={{ width: "20%", position: "relative", backgroundColor: "#EF1C20", paddingTop: "3%" }}>
              <div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7639 18.4089L15.7766 13.7896C16.9035 12.3501 17.6029 10.5316 17.6029 8.5617C17.6029 3.82624 13.6394 0 8.8209 0C3.96357 0 0 3.82624 0 8.5617C0 13.2971 3.96357 17.1234 8.78204 17.1234C10.9193 17.1234 12.8622 16.3657 14.4165 15.1534C14.4165 15.1534 18.093 19.4317 18.2873 19.659C18.4816 19.8863 19.0256 20 19.0256 20C19.0256 20 19.4919 19.9242 19.6474 19.7727C20.0748 19.3939 20.1137 18.8256 19.7639 18.4089ZM1.94293 8.5617C1.94293 4.88699 5.01276 1.89418 8.78204 1.89418C12.5513 1.89418 15.6211 4.88699 15.6211 8.5617C15.6211 12.2364 12.5513 15.2292 8.78204 15.2292C5.05161 15.2292 1.94293 12.2364 1.94293 8.5617Z"
                    fill="#F2F2F2"
                  />
                </svg>
                <label style={{ fontSize: 12, color: "#ffffff", marginLeft: 10 }}>Search product</label>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ display: "none" }} className="index-five-screen">
        <div className={"container"}>
          <ul className="lines">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div style={{ width: "50%", position: "relative" }}>
              <div className="text-center" style={{ paddingTop: "20%" }}>
                <img style={{ width: "70%" }} src={hiring} />
              </div>
            </div>
            <div style={{ width: "50%", position: "relative" }}>
              <div className="section-title">
                <div style={{ color: "#EF1C20", fontSize: 16 }}>Career</div>
                <div style={{ color: "#333333", fontSize: 33, marginTop: 20 }}>
                  Bring your right attitude we’re <label style={{ color: "#EF1C20" }}>Hiring</label> new talents for our Compnay.
                </div>
                <div style={{ color: "#4F4F4F", fontSize: 12, marginTop: 30 }}>Our commitment towards sustained excellence includes our efforts to benefit the coOur commitment towards sustained</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ display: "none" }} className="index-six-screen">
        <div className={"container"}>
          <ul className="lines">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div style={{ width: "50%", position: "relative" }}>
              <div className="section-title">
                <div style={{ color: "#EF1C20", fontSize: 16 }}>Our Bogs</div>
                <div style={{ color: "#333333", fontSize: 33, marginTop: 20 }}>A leading nutraceutical company in Bangladesh</div>
                <div style={{ color: "#4F4F4F", fontSize: 12, marginTop: 30 }}>Our commitment towards sustained excellence includes our efforts to benefit the coOur commitment towards sustained</div>
              </div>
            </div>
            <div style={{ width: "50%", position: "relative" }}></div>
          </div>
          <div className="row" style={{ margin: 0, padding: 10 }}>
            <div className="blog_info_card">
              <div>
                <img width="100%" src={bog_post_img} />
              </div>
              <div className="d-flex flex-row" style={{ marginTop: 10, padding: 10 }}>
                <div className="p-2 blog_tag">Remote</div>
                <div className="p-2 blog_tag" style={{ marginLeft: 8 }}>
                  Internship
                </div>
                <div className="p-2 blog_tag" style={{ marginLeft: 8 }}>
                  Full-Time
                </div>
              </div>
              <div style={{ color: "##333333", fontSize: 17, padding: 5 }}>We provide any types of services at all stages of the solid mineral depos...</div>
              <div className="d-flex justify-content-between" style={{ marginTop: 10 }}>
                <div className="d-flex flex-row">
                  <div className="p-2">
                    <img width="40px" src={bog_post_small} />
                  </div>
                  <div className="p-2">
                    <div style={{ fontSize: 13 }}>Kathryn Murphy</div>
                    <div style={{ fontSize: 11 }}>Title here</div>
                  </div>
                </div>
                <div className="justify-content-center align-self-center" style={{ fontSize: 13, paddingRight: 8 }}>
                  05 min to read
                </div>
              </div>
            </div>
            <div className="blog_info_card" style={{ marginLeft: "2%" }}>
              <div>
                <img width="100%" src={bog_post_img} />
              </div>
              <div className="d-flex flex-row" style={{ marginTop: 10, padding: 10 }}>
                <div className="p-2 blog_tag">Remote</div>
                <div className="p-2 blog_tag" style={{ marginLeft: 8 }}>
                  Internship
                </div>
                <div className="p-2 blog_tag" style={{ marginLeft: 8 }}>
                  Full-Time
                </div>
              </div>
              <div style={{ color: "##333333", fontSize: 17, padding: 5 }}>We provide any types of services at all stages of the solid mineral depos...</div>
              <div className="d-flex justify-content-between" style={{ marginTop: 10 }}>
                <div className="d-flex flex-row">
                  <div className="p-2">
                    <img width="40px" src={bog_post_small} />
                  </div>
                  <div className="p-2">
                    <div style={{ fontSize: 13 }}>Kathryn Murphy</div>
                    <div style={{ fontSize: 11 }}>Title here</div>
                  </div>
                </div>
                <div className="justify-content-center align-self-center" style={{ fontSize: 13, paddingRight: 8 }}>
                  05 min to read
                </div>
              </div>
            </div>
            <div className="blog_info_card" style={{ marginLeft: "2%" }}>
              <div>
                <img width="100%" src={bog_post_img} />
              </div>
              <div className="d-flex flex-row" style={{ marginTop: 10, padding: 10 }}>
                <div className="p-2 blog_tag">Remote</div>
                <div className="p-2 blog_tag" style={{ marginLeft: 8 }}>
                  Internship
                </div>
                <div className="p-2 blog_tag" style={{ marginLeft: 8 }}>
                  Full-Time
                </div>
              </div>
              <div style={{ color: "##333333", fontSize: 17, padding: 5 }}>We provide any types of services at all stages of the solid mineral depos...</div>
              <div className="d-flex justify-content-between" style={{ marginTop: 10 }}>
                <div className="d-flex flex-row">
                  <div className="p-2">
                    <img width="40px" src={bog_post_small} />
                  </div>
                  <div className="p-2">
                    <div style={{ fontSize: 13 }}>Kathryn Murphy</div>
                    <div style={{ fontSize: 11 }}>Title here</div>
                  </div>
                </div>
                <div className="justify-content-center align-self-center" style={{ fontSize: 13, paddingRight: 8 }}>
                  05 min to read
                </div>
              </div>
            </div>
          </div>
          <div className="row blog_control">
            <div className="blog_control_btn_right d-flex blog_control_box border_left_white border_top_white border_bottom_white" style={{ width: "15%" }}>
              <div className="text-center w-100 justify-content-center align-self-center ">
                <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.162406 9.99973C0.162406 9.7777 0.250814 9.56472 0.407537 9.40766L9.57082 0.245382C9.898 -0.0817941 10.4281 -0.0817941 10.7546 0.245382C11.0818 0.572559 11.0818 1.10234 10.7546 1.42918L2.18306 9.99973L10.7539 18.5709C11.0811 18.8981 11.0811 19.4279 10.7539 19.7547C10.4268 20.0816 9.89699 20.0819 9.57015 19.7547L0.407872 10.5918C0.250814 10.4347 0.162406 10.2218 0.162406 9.99973Z" fill="#999194" />
                </svg>
              </div>
            </div>
            <div ref={blogControlBoxPolylineRef} className="blog_control_box border_left_white border_top_white border_bottom_white" style={{ width: "25%" }}>
              <svg width="100%" height="100%">
                <polyline points={`0,0 ${blogControlBoxPolylineWidth},78`} strokeOpacity="0.8" stroke="#F0EBED" strokeWidth="1" fill="none"></polyline>
              </svg>
            </div>
            <div className="d-flex blog_control_box border_left_white border_top_white border_bottom_white" style={{ width: "20%" }}>
              <div className="blog_view_all_btn text-center w-100 justify-content-center align-self-center">View All</div>
            </div>
            <div className="blog_control_box border_left_white border_top_white border_bottom_white" style={{ width: "25%" }}>
              <svg width="100%" height="100%">
                <polyline points={`0,0,${blogControlBoxPolylineWidth},78`} strokeOpacity="0.8" stroke="#F0EBED" strokeWidth="1" fill="none"></polyline>
              </svg>
            </div>
            <div className="blog_control_btn_left d-flex blog_control_box border_left_white border_top_white border_bottom_white" style={{ width: "15%" }}>
              <div className="text-center w-100 justify-content-center align-self-center ">
                <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8376 9.99973C10.8376 9.7777 10.7492 9.56472 10.5925 9.40766L1.42918 0.245382C1.102 -0.0817941 0.571889 -0.0817941 0.245382 0.245382C-0.0817941 0.572559 -0.0817941 1.10234 0.245382 1.42918L8.81694 9.99973L0.246053 18.5709C-0.0811234 18.8981 -0.0811234 19.4279 0.246053 19.7547C0.573229 20.0816 1.10301 20.0819 1.42985 19.7547L10.5921 10.5918C10.7492 10.4347 10.8376 10.2218 10.8376 9.99973Z" fill="#999194" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ display: "none" }} className="index-seven-screen">
        <div className={"container"}>
          <ul className="lines">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div style={{ width: "50%", position: "relative" }}>
              <div className="section-title">
                <div style={{ color: "#EF1C20", fontSize: 16 }}>Our clients</div>
                <div style={{ color: "#333333", fontSize: 33, marginTop: 20 }}>A leading nutraceutical company in Bangladesh</div>
                <div style={{ color: "#4F4F4F", fontSize: 12, marginTop: 30 }}>Our commitment towards sustained excellence includes our efforts to benefit the coOur commitment towards sustained</div>
              </div>
            </div>
            <div style={{ width: "50%", position: "relative" }}></div>
          </div>

          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div className="d-flex justify-content-center align-items-center radinat_company_list_name">
              <div className="radinat_company_list_box border_top_white border_bottom_white border_left_white border_right_white d-flex flex-column" style={{ height: 150 }}>
                <div className="d-flex h-100">
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img width="100px" src={bloomberg_img} />
                  </div>
                </div>
              </div>
              <div className="radinat_company_list_box border_top_white border_bottom_white border_right_white d-flex flex-column" style={{ height: 150 }}>
                <div className="d-flex h-100">
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img width="100px" src={hubspot_img} />
                  </div>
                </div>
              </div>
              <div className="radinat_company_list_box border_top_white border_bottom_white border_right_white d-flex flex-column" style={{ height: 150 }}>
                <div className="d-flex h-100">
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img width="100px" src={usa_today_img} />
                  </div>
                </div>
              </div>
              <div className="radinat_company_list_box border_top_white border_bottom_white d-flex flex-column" style={{ height: 150 }}>
                <div className="d-flex h-100">
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img width="100px" src={bloomberg_img} />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center radinat_company_list_name">
              <div className="radinat_company_list_box  border_bottom_white border_left_white border_right_white d-flex flex-column" style={{ height: 150 }}>
                <div className="d-flex h-100">
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img width="100px" src={hubspot_img} />
                  </div>
                </div>
              </div>
              <div className="radinat_company_list_box  border_bottom_white border_right_white d-flex flex-column" style={{ height: 150 }}>
                <div className="d-flex h-100">
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img width="100px" src={usa_today_img} />
                  </div>
                </div>
              </div>
              <div className="radinat_company_list_box  border_bottom_white border_right_white d-flex flex-column" style={{ height: 150 }}>
                <div className="d-flex h-100">
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img width="100px" src={bloomberg_img} />
                  </div>
                </div>
              </div>
              <div className="radinat_company_list_box  border_bottom_white d-flex flex-column" style={{ height: 150 }}>
                <div className="d-flex h-100">
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img width="100px" src={hubspot_img} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="index-seven-screen">
        <div className={"container"}>
          <ul className="lines">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div style={{ marginTop: 40, width: "50%", position: "relative" }}>
              <div className="row border_bottom_white " style={{ margin: 0, padding: 0 }}>
                <div className="contact_img_box d-flex" style={{ backgroundColor: "#FDE4E4", width: "50%" }}>
                  <div className="text-center justify-content-center align-self-center w-100">
                    <img style={{ width: 50 }} src={contact_us_img} />
                    <div style={{ fontSize: 12, color: "#EF1C20", marginTop: 10 }}>Download Presentation</div>
                  </div>
                </div>
                <div ref={contactBoxPolylineRef} style={{ width: "50%" }}>
                  <svg width="100%" height="100%">
                    <polyline points={`0,0,${contactBoxPolylineWidth},300`} strokeOpacity="0.8" stroke="#F0EBED" strokeWidth="1" fill="none"></polyline>
                  </svg>
                </div>
              </div>
              <div className="text-center border_left_white border_bottom_white" style={{ position: "relative", padding: 40, backgroundColor: "#ffffff", zIndex: 16 }}>
                <div style={{ color: "#999194", fontSize: 12 }}>SKS Office phone number</div>
                <div style={{ color: "#000000", fontSize: 26, fontWeight: "bold" }}>+880 2 9835717</div>
              </div>
            </div>
            <div style={{ width: "50%", position: "relative" }}>
              <div className="section-title">
                <div style={{ color: "#EF1C20", fontSize: 16 }}>Contact Us</div>
                <div style={{ color: "#333333", fontSize: 33, marginTop: 20 }}>Trusted name for quality pharmaceutical products</div>
              </div>
              <div className="d-flex flex-row border_top_white border_bottom_white border_left_white" style={{ position: "relative", zIndex: 15, backgroundColor: "#ffffff" }}>
                <div className="p-2" style={{ width: "80%", padding: 35 }}>
                  <div style={{ color: "#999194", fontSize: 12 }}>Radiant Pharmaceuticals Limited.</div>
                  <div style={{ color: "#999194", fontSize: 12 }}>SKS Tower (7th Floor & 8th Floor),</div>
                  <div style={{ color: "#999194", fontSize: 12 }}>7 VIP Road, Mohakhali,</div>
                  <div style={{ color: "#999194", fontSize: 12 }}>Dhaka 1206, Bangladesh.</div>
                </div>
                <div className="p-2 d-flex border_left_white" style={{ width: "20%" }}>
                  <div className="text-center justify-content-center align-self-center w-100">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.9999 0C8.04222 0 3.19531 4.84691 3.19531 10.8045C3.19531 12.8618 3.75592 15.0459 4.86148 17.2961C5.73812 19.0804 6.95831 20.9116 8.4882 22.7387C11.0854 25.8406 13.6413 27.8309 13.7488 27.9142C13.8227 27.9713 13.9113 27.9999 13.9999 27.9999C14.0884 27.9999 14.1771 27.9713 14.251 27.9142C14.3585 27.831 16.9144 25.8406 19.5116 22.7387C21.0414 20.9115 22.2617 19.0803 23.1383 17.2961C24.2439 15.0459 24.8045 12.8618 24.8045 10.8045C24.8045 4.84691 19.9576 0 13.9999 0ZM13.9999 7.06908C16.0596 7.06908 17.7353 8.74477 17.7353 10.8045C17.7353 12.8642 16.0596 14.54 13.9999 14.54C11.9401 14.54 10.2645 12.8643 10.2645 10.8045C10.2645 8.74477 11.9401 7.06908 13.9999 7.06908Z"
                        fill="#FF4A4A"
                      />
                      <path
                        d="M13.9998 0C13.5944 0 13.1943 0.0231875 12.8003 0.0669376C18.1955 0.665603 22.4054 5.25231 22.4054 10.8046C22.4054 12.8618 21.8448 15.0459 20.7392 17.2962C19.8626 19.0805 18.6424 20.9116 17.1125 22.7387C15.4462 24.7289 13.7969 26.2614 12.8003 27.1264C13.357 27.6095 13.7101 27.8844 13.7487 27.9142C13.8226 27.9713 13.9112 27.9999 13.9998 27.9999C14.0883 27.9999 14.1769 27.9713 14.2508 27.9142C14.3584 27.831 16.9143 25.8406 19.5114 22.7387C21.0413 20.9115 22.2615 19.0803 23.1382 17.2961C24.2437 15.0459 24.8043 12.8618 24.8043 10.8045C24.8043 4.84691 19.9574 0 13.9998 0Z"
                        fill="#E7343F"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="row border_left_white border_bottom_white" style={{ position: "relative", zIndex: 15, backgroundColor: "#ffffff", padding: 0, margin: 0 }}>
                <div style={{ width: "40%", padding: 40 }}>
                  <div style={{ color: "#EF1C20", fontSize: 12, cursor: "pointer" }}>View contact page</div>
                </div>
                <div ref={contactViewBoxPolylineRef} className="border_left_white" style={{ width: "40%" }}>
                  <svg width="100%" height="98px">
                    <polyline points={`0,0,${contactViewBoxPolylineWidth},${contactViewBoxPolylineHeight}`} strokeOpacity="0.8" stroke="#F0EBED" strokeWidth="1" fill="none"></polyline>
                  </svg>
                </div>
                <div className="d-flex border_left_white" style={{ width: "20%" }}>
                  <div className="text-center justify-content-center align-self-center w-100">
                    <a target="_blank" href="https://www.google.com/search?rlz=1C1CHBD_enBD902BD902&tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=ALeKk020tXbnkXN_KqtylAF_wjiZQDxZmg:1627919404069&q=Radiant+Pharmaceuticals&rflfq=1&num=10&ved=2ahUKEwjMqLWu2JLyAhVrwjgGHbCcBdQQtgN6BAgOEAM#rlfi=hd:;si:3022237785770684840,l,ChdSYWRpYW50IFBoYXJtYWNldXRpY2Fsc0jFnoCAm6qAgAhaIRAAEAEYABgBIhdyYWRpYW50IHBoYXJtYWNldXRpY2Fsc5IBFnBoYXJtYWNldXRpY2FsX2NvbXBhbnk;mv:[[23.9039595,90.41405069999999],[23.729209899999997,90.364269]]">
                      <img width="30px" src={map_icon} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Context.Provider>
  );
};

export default Home;
