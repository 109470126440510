import React, {useEffect, useState} from "react";
import {Context} from "../../components/home/Home";
import logo from "../../assets/images/radiant_logo.svg";
import './Style/footer_style.css';
import linkedin_icon from "../../assets/images/linkedin_icon.svg";
import facebook_icon from "../../assets/images/facebook_icon.svg";
import youtub_icon from "../../assets/images/youtub_icon.svg";

export default () => {

    return (
      <div>
          <section className='footer-screen'>

              <ul className="lines dark">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
              </ul>

              <div className='row' style={{margin: 0, padding: 0}}>
                  <div style={{width: "31.10%"}}>
                      <div style={{paddingTop: 30, paddingLeft: 60, marginBottom: 80}}>
                          <img src={logo} />
                      </div>
                      <div className='row' style={{margin: 0, padding: 0}}>
                          <div className='border_right_dark' style={{width: "30%"}}></div>
                          <div className='border_top_dark' style={{width: "70%", height: '100%'}}>
                              <div style={{padding: 30}}>
                                <div style={{color: '#999194', fontSize: 14}}>
                                    11 Services from Radiant Group
                                </div>
                                  <div style={{color: '#ffffff', fontSize: 16}}>
                                      View all
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div style={{width: "18.9%"}}>
                      <div className='footer-main-menu' style={{paddingTop : 60, paddingLeft: 30}}>
                          <ul>
                              <li>HOME</li>
                              <a href="http://www.radiantpharmabd.com/products"><li>PRODUCTS</li></a>
                              <a href="http://www.radiantpharmabd.com/sustainability"><li>SASTAINABLITY</li></a>
                              <a href="http://www.radiantpharmabd.com/international-business-overview"><li>GLOBAL AMPLIFICATIONS</li></a>
                              <a href="http://www.radiantpharmabd.com/pharmacovigilance"><li>Pharmacovigilance</li></a>
                              <a href="http://www.radiantpharmabd.com/csr-our-commitment"><li>CSR</li></a>
                              <a href="http://www.radiantpharmabd.com/career"><li>CAREER</li></a>
                          </ul>
                      </div>
                  </div>
                  <div  style={{width: "18.9%"}}>
                      <div className='footer-menu' style={{paddingTop : 60, paddingLeft: 30}}>
                          <ul>
                              <a href="http://www.radiantpharmabd.com/about-us"><li>ABOUT RADIANT</li></a>
                              <a href="http://www.radiantpharmabd.com/mission"><li>MISSION VISION & CORE VALUE</li></a>
                              <a href="http://www.radiantpharmabd.com/history"><li>HISTORY</li></a>
                              <a href="http://www.radiantpharmabd.com/meet-our-chairman"><li>CHAIRMAN MESSAGES</li></a>
                              <a href="http://www.radiantpharmabd.com/contact-us"><li>CONTACT US</li></a>
                          </ul>
                      </div>
                  </div>
                  <div  style={{width: "18.9%"}}>
                      <div className='text-center border_top_dark' style={{marginTop : 174, paddingTop: 15, paddingBottom: 15}}>
                          <div style={{color: "#999194", fontSize: 13}}>
                            SKS Office phone number
                          </div>
                          <div style={{color: "#ffffff", fontSize: 20, fontWeight: 'bold'}}>
                              +880 2 9835717
                          </div>
                      </div>
                  </div>
                  <div style={{width: "12.2%", zIndex: 15}}>
                      <div className="100 d-flex flex-column">
                          <a target="_blank" href="https://www.linkedin.com/company/13714589">
                              <div className="border_bottom_dark h-100 align_center">
                                  <div className="footer-social-logo w-100 text-center">
                                      <img src={linkedin_icon} />
                                      <div style={{color: '#ffffff', fontSize: 12, marginTop: 10}}>Linkedin</div>
                                  </div>
                              </div>
                          </a>
                          <a target="_blank" href="https://www.facebook.com/RadiantPharmaceuticalsLimited">
                              <div className="border_bottom_dark h-100 align_center">
                                  <div className="footer-social-logo w-100 text-center">
                                      <img src={facebook_icon} />
                                      <div style={{color: '#ffffff', fontSize: 12, marginTop: 10}}>Facebook</div>
                                  </div>
                              </div>
                          </a>
                          <a target="_blank" href="https://www.youtube.com/channel/UCeuvxGn6xJB-4KbRGJYwf0A">
                              <div className="h-100 align_center">
                                  <div className="footer-social-logo w-100 text-center">
                                      <img width="24px" src={youtub_icon} />
                                      <div style={{color: '#ffffff', fontSize: 12, marginTop: 10}}>Youtub</div>
                                  </div>
                              </div>
                          </a>
                      </div>
                  </div>
              </div>

              <div className='border_top_dark' style={{height: 120}}>

              </div>
          </section>
      </div>
    );
};


