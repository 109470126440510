import React, {useContext, useEffect, useState} from "react";
import './Style/text_slider_style.css';
import {Context} from "../home/Home";

const TextSlider = () => {
    const {textChangeValue} = useContext(Context);

    useEffect(() => {}, [textChangeValue]);

    return (
        <div className="vam-box">
            <div className={`vam js-item ${textChangeValue[0]}`}>
                <h1 style={{fontSize: 50}}>Responsibility towards our stakeholders</h1>
                <p style={{fontSize: 20}}>Our commitment towards sustained excellence includes our <br/>
                efforts to benefit the communities we work with</p>
            </div>
            <div className={`vam js-item ${textChangeValue[1]}`}>
                <h1 style={{fontSize: 50}}>Take your next Career step with us.</h1>
                <p style={{fontSize: 20}}>If you have the Talent we will provide the opportunity.</p>
            </div>
            <div className={`vam js-item ${textChangeValue[2]}`}>
                <h1 style={{fontSize: 40}}>Top of the line Manufacturing facility in Bangladesh</h1>
                <p style={{fontSize: 20}}>With a dedicated team using state of the art equipment and <br/>
                    highly automated pharmaceutical manufacturing processes, Radiant has been locally manufacturing global pharmaceutical<br/>
                    innovators’s brands.</p>
            </div>
            <div className={`vam js-item ${textChangeValue[3]}`}>
                <h1 style={{fontSize: 50}}>Countrywide Distribution Network</h1>
                <p style={{fontSize: 20}}>Radiant reaches out to 15000 customers per day using the  <br/>
                    distribution network of Radiant Distribution Limited.</p>
            </div>
        </div>
    )
}

export default TextSlider;
