import React, {useEffect, useRef, useState} from "react";


const AboutUs = () => {

    return (
        <div>
            About us
        </div>
    )
}

export default AboutUs;
