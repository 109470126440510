import React, {useContext, useEffect, useState} from "react";
import './Style/image_slider_control_style.css';
import {Context} from "../home/Home";

const ImageSliderControl = () => {

    const {setImageChangeValue} = useContext(Context);
    const [progressActive, setProgressActive] = useState(['active','','','']);
    const [currentDot, setCurrentDot] = useState(0);
    const [progressBar, setProgressBar] = useState([88,88,88,88]);
    const {imageChangeComplete} = useContext(Context);
    // const [nextImage, setNextImage] = useState(false);

    useEffect( () => {
        // console.log(imageChangeComplete);
        updateProgressBar(imageChangeComplete);

    }, [imageChangeComplete]);

    const updateProgressBar = async (progressBarPosition) => {

        const circle = await progressCircle(progressBarPosition);

        let allProgressBar = [...progressBar];
        allProgressBar[progressBarPosition] = 88;
        setProgressBar(allProgressBar);

        setImageChangeValue(currentDot);
        if((progressActive.length-1) === currentDot){
            let allProgressActive = [...progressActive];
            allProgressActive[0] = "active";
            allProgressActive[currentDot] = "";
            setCurrentDot(0);
            setProgressActive(allProgressActive);
        }else{
            let allProgressActive = [...progressActive];
            allProgressActive[currentDot] = "";
            allProgressActive[currentDot+1] = "active";
            setCurrentDot(currentDot+1);
            setProgressActive(allProgressActive);
        }
    };

    const progressCircle = async (progressBarPosition) => {
        for (let i=1; i<=89; i++){
            let allProgressBar = [...progressBar];
            allProgressBar[progressBarPosition] = 89-i;
            setProgressBar(allProgressBar);
            await timeout(50);
        }
        return true;
    };

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const leftClick = () => {

    };

    const rightClick = () => {
        // setNextImage(true);
    };

    return (
        <>
            <div className="position-dots bleft text-center" style={{width: '67%', position: 'relative', margin: 0}}>
                <ul className='dots' style={{position: 'relative', margin: 0, padding: 0}}>
                    <li className={`dot ${progressActive[0]}`}>
                        <svg className="circle">
                            <circle cx="15" cy="15" r="14"></circle>
                        </svg>
                        <svg className="circle_progress" data-time="5" style={{strokeDashoffset: progressBar[0]}}>
                            <circle cx="15" cy="15" r="14"></circle>
                        </svg>
                        <div className="round"></div>
                    </li>
                    <li className={`dot ${progressActive[1]}`}>
                        <svg className="circle">
                            <circle cx="15" cy="15" r="14"></circle>
                        </svg>
                        <svg className="circle_progress" data-time="5" style={{strokeDashoffset: progressBar[1]}}>
                            <circle cx="15" cy="15" r="14"></circle>
                        </svg>
                        <div className="round"></div>
                    </li>
                    <li className={`dot ${progressActive[2]}`}>
                        <svg className="circle">
                            <circle cx="15" cy="15" r="14"></circle>
                        </svg>
                        <svg className="circle_progress" data-time="5" style={{strokeDashoffset: progressBar[2]}}>
                            <circle cx="15" cy="15" r="14"></circle>
                        </svg>
                        <div className="round"></div>
                    </li>
                    <li className={`dot ${progressActive[3]}`}>
                        <svg className="circle">
                            <circle cx="15" cy="15" r="14"></circle>
                        </svg>
                        <svg className="circle_progress" data-time="5" style={{strokeDashoffset: progressBar[3]}}>
                            <circle cx="15" cy="15" r="14"></circle>
                        </svg>
                        <div className="round"></div>
                    </li>
                </ul>
            </div>
            <div className='arrs bleft' style={{width: '33%', position: 'relative', margin: 0, padding: 0, height: '100%'}}>
                <div className='arr arrl' onClick={() => leftClick()}>
                    <svg>
                        <path fill="none" strokeWidth="2" d="M17 2L6 12l11 10"></path>
                    </svg>
                </div>
                <div className='arr arrr' onClick={() => rightClick()}>
                    <svg>
                        <path fill="none" strokeWidth="2" d="M7 22l11-10L7 2"></path>
                    </svg>
                </div>
            </div>
        </>
    )
};

export default ImageSliderControl;
