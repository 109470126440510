import React, { useState, Fragment } from "react";
import Home from "../../components/home/Home";

export default (props) => {

    return (
        <>
            <Home/>
        </>
    )
}
