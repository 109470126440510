import React, { useState, useEffect, Suspense } from 'react';
import Routes from '../routes/Router';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from '../layouts/layout-component/Header';
import Footer from "./layout-component/Footer";

export default (props) => {

    return (
        <>
            <Header/>

                <div className={"full_screen"}>
                    <Switch>
                        {Routes.map((prop, key) => {
                            if (prop.redirect) {
                                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                            } else if (prop.name) {
                                return (
                                    <Route path={prop.path} component={prop.component} key={key} />
                                );
                            }
                        })}
                    </Switch>

                </div>

            <Footer/>
        </>
    )
}

