
import HomePage from "../views/home/HomeView";
import AboutUsPage from "../views/about-us/AboutUsView";

var ThemeRoutes = [

    {
        path: "/about-us",
        name: "About Us",
        component: AboutUsPage
    },
    {
        path: "/",
        name: "Home",
        component: HomePage
    },
    // { path: "/", pathTo: "/", name: "Home", redirect: true },

]


export default ThemeRoutes;
