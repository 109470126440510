import React, {useContext, useEffect, useState} from "react";
import './Style/image_slider_style.css';
import {Context} from "../home/Home";

const ImageSlider = () => {

    const {setTextChangeValue} = useContext(Context);

    const images = ["/images/slider/b1.png", "/images/slider/b2.png", "/images/slider/b3.png", "/images/slider/b4.png"];
    // const [images, setImages] = useState(["/images/slider/b1.jpg", "/images/slider/b2.jpg", "/images/slider/b3.jpg", "/images/slider/b4.jpg"]);
    const [imageActive, setImageActive] = useState(['active','','','']);
    const [textActive, setTextActive] = useState(['active','','','']);
    const {imageChangeValue} = useContext(Context);
    const {setImageChangeComplete} = useContext(Context);

    useEffect(() => {
        if(imageChangeValue !== null){
            if((images.length-1) === imageChangeValue){
                changeImage(imageChangeValue, 0);
            }else{
                changeImage(imageChangeValue, imageChangeValue+1);
            }
        }
    }, [imageChangeValue]);


    const changeImage = async (outImage, inImage) => {
        let allImageActive = [...imageActive];
        allImageActive[outImage] = "navOutNext";
        allImageActive[inImage] = "navInNext active";

        let allTextActive = [...textActive];
        allTextActive[outImage] = "inactive";
        allTextActive[inImage] = "";
        setTextChangeValue(allTextActive);

        setImageActive(allImageActive);
        setTextActive(allTextActive);

        await timeout(600);
        let allTextActive_n = [...textActive];
        allTextActive_n[outImage] = "";
        allTextActive_n[inImage] = "active";
        setTextChangeValue(allTextActive_n);
        setTextActive(allTextActive_n);

        setImageChangeComplete(inImage);

    };

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    return (
        <div className="slider">
            <div className={`stage`}>
                {images.map((img,i) => (
                    <div key={i} data-src={img} className={`slide fxRollX ${imageActive[i]}`}>
                        <div className="image" style={{backgroundImage: `url("${img}")`}}></div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default ImageSlider;
