import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import indexRoutes from "./routes/";
import { PrivateRoute } from "./routes/PrivateRoutes";
import './App.css';

const App = () => {

  return (
      <BrowserRouter>
        <Switch>

          {indexRoutes.map((prop, key) => {
            return (
                <PrivateRoute
                    path={prop.path}
                    key={key}
                    component={prop.component}
                />
            );
          })}

        </Switch>
      </BrowserRouter>
  );

};

export default App;
