import React, { useState, Fragment } from "react";
import AboutUs from "../../components/about-us/AboutUs";

export default (props) => {

    return (
        <>
            <AboutUs/>
        </>
    )
}
